import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { navigate } from "gatsby"
import Img from 'gatsby-image'
import {ReadMoreButton} from 'components/buttons'
import Tag from "components/tag"

import CardStyle from './contentCardWithMeta.module.css'

export const ContentCardWithMeta = React.forwardRef(({image, title, tags, date, link}, ref) => (
  <div className={CardStyle.container} onClick={()=>{navigate(link.url)}} ref={ref}>
    <div className={CardStyle.media} >
        {image &&
         <Img
           fluid={image}
           className={CardStyle.image}
           style={{height:261}}


         />
        }
        {tags &&
          <div className={CardStyle.tagsContainer}>
            {tags.map((tag, index) => {
              if(tag) {
                return(
              <div key={tag.name} className={CardStyle.tag}>
                <Tag title={tag.name}  />
              </div>
                )
              }
            })}
          </div>
        }
      </div>
      {date &&
       <div className={CardStyle.top}>{date}</div>
      }
      <div className={CardStyle.title} dangerouslySetInnerHTML={{__html:title}} />
      {link ?
        <ReadMoreButton
          link={link}
          fontColor='black'
          symbolColor='#AAC939'
        />
        : ''
      }
    </div>
))
