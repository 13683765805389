import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {NewsArchive} from 'components/news/archive'
const DefaultTemplate = ({data, ...props}) => {
  return (
    <Layout
      articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
      articleClassName={`${data.wordpressPage.classes}`}
      context = {props.pageContext}
      location = {props.location}
      ancestors = {data.wordpressPage.ancestors}
    >
      <>
        <NewsArchive {...data.wordpressPage}/>
      </>
    </Layout>
  )
}
  


export default DefaultTemplate
export const pageDefautQuery = graphql `
query AllNewsPageById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
    ancestors {
      link
      label
    }
  }
  allWordpressWpNews{
   nodes{
     title
     path
     wordpress_id
     tags {
       name
     }
   }
  }
}
`
