import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

export const withAllNews = () => {
  const {news} = useStaticQuery(graphql `
    query allNews {
      news:allWordpressWpNews(sort:{fields:[date],order:DESC}){
        nodes{
          id
          title
          path
          wordpress_id
          date(formatString: "LL")
          tags {
            id
            name
          }
          featured_media  {
            localFile {
              childImageSharp {
                fluid ( maxWidth:400,  quality:100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    `)
  //normalize news to be used with cards
  const items = news.nodes.map(item=>{
    let image = false;
    if(item.featured_media && item.featured_media.localFile && item.featured_media.localFile.childImageSharp && item.featured_media.localFile.childImageSharp.fluid){
      image = item.featured_media.localFile.childImageSharp.fluid
    }
    return {
      id: item.id,
      tags:item.tags,
      image:image,
      title:item.title,
      date:item.date,
      link:{
        url:item.path,
        title:"read more"
      }
    }
  })
  return items;
}
