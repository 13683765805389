import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
export const withAllTags = () => {
  const {news} = useStaticQuery(graphql `
    query allNewsWithTags {
      news:allWordpressWpNews{
        nodes{
          id
          tags {
            id
            name
          }
        }
      }
    }
`)
  let tags = {}
  news.nodes.forEach((item, index)=>{
    if(item.tags && item.tags.length){
      item.tags.forEach((tag, index)=>{
        if(!tags.hasOwnProperty(tag.id)){
          tags[tag.id]={
            ...tag,
            items: []
          }
        }
        tags[tag.id].items.push(item.id)
      })
    }
  })
  return Object.values(tags);
}
