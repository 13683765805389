import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {withGrid} from 'components/grid'
import Styles from './archive.module.css'
import {withAllTags} from './hooks/withalltags'
import {withAllNews} from './hooks/withallnews'
import {ContentCardWithMeta} from 'components/cards/contentCardWithMeta'
import {TitleBanner} from 'components/banners/titleBanner'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'



export const NewsArchive = (props) => {
  const allNews = withAllNews()
  const allTags = withAllTags()
  const Grid = withGrid(ContentCardWithMeta, allNews, {tags:allTags})
  return (
    <>
      <TitleBanner title={props.title} content={props.content}/>
      <div className="entry">
        <div className="entry-content relative">
          <BgPattern className={`${BgStyle.pattern3} ${Styles.bgTop}`}/>
          <div className={Styles.container}>
            
            <Grid/>
          </div>
        </div>
      </div>
      <footer className="entry-footer mt-10">
      </footer>
    </>
  )
}
