import React from 'react'
import {Link} from 'components/link'
import Styles from './tag.module.css'

export default (props) => (
  <>
    {props.url ?

     <Link to={props.url} target={props.target}>
     <button dangerouslySetInnerHTML={{__html:props.title}} className={Styles.tag}/>
     </Link>
     :
     <button dangerouslySetInnerHTML={{__html:props.title}} className={Styles.tag}/>
    }

  </>
)

